import {
  Timeline,
  timelineItemClasses,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { dateUtil } from "@sdeapps/react-core";
import { LoadingScreen } from "components/Loading";
import type { DocumentGenerationAuditForTimeline } from "models";
import { courriersService } from "services";

interface DocumentGenerationTimelineProps {
  isLoading: boolean;
  documentAudits: Array<DocumentGenerationAuditForTimeline>;
}
function DocumentGenerationTimeline({
  isLoading,
  documentAudits,
}: Readonly<DocumentGenerationTimelineProps>): ReactNode {
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (documentAudits.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        Aucun document généré à ce jour
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        ".MuiTimeline-root": {
          marginBlockStart: 0,
        },
      }}>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
        {documentAudits?.map((audit) => (
          <TimelineItem key={audit.id}>
            <TimelineSeparator>
              <TimelineDot
                variant="filled"
                sx={{
                  backgroundColor: `${audit.typeDossier.toLowerCase()}.main`,
                }}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary">
                {dateUtil.format(audit.dateUtc, "dd/MM/yyyy")} {audit.auteur.displayName}
              </Typography>
              <Tooltip
                title={`${audit.nomDocument} généré le ${dateUtil.format(
                  audit.dateUtc +
                    // Ajout du suffixe Z afin de faire une conversion d'une date UTC et non d'une date non localisée
                    (audit.dateUtc.at(-1) === "Z" ? "" : "Z"),
                  "dd/MM/yyyy HH:mm"
                )} par ${audit.auteur.displayName}`}
                placement="top-start">
                <Link href={audit.cheminRelatifDocument} target="_blank">
                  {courriersService.getDocumentLibelleByDocumentName(audit.nomDocument)}
                </Link>
              </Tooltip>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
}

export default DocumentGenerationTimeline;
