import { type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import type { DossierAss } from "models";
import { CourriersDossierAssDefinitions } from "models";
import { withPageErrorBoundary } from "utils/errorHandling";
import { useDossier, useChantier } from "providers";
import DossierSections from "../DossierSections";
import { dossierAssSections } from "../routes/dossiersSectionsDefinitions";
import { DossierCourriersForm } from "../DossierCourriersForm";

function DossierAssFormCourriers(): ReactNode {
  const { chantier, isLoading: isChantierLoading } = useChantier();
  const { dossier, isLoading: isDossierLoading = false } = useDossier<DossierAss>();

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierAssSections} noSaveButton />
      <DossierCourriersForm
        chantier={chantier}
        dossier={dossier}
        isLoading={isChantierLoading || isDossierLoading}
        courriersMetadata={CourriersDossierAssDefinitions}
      />
    </Grid>
  );
}

export const DossierAssFormCourriersWithErrorBoundary =
  withPageErrorBoundary(DossierAssFormCourriers);
