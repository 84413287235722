import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { PageLayout } from "components/Layout";
import { Button, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { chantierService } from "services";
import { enqueueSnackbar } from "notistack";
import { ToastMessages } from "enums";
import { routesConfig } from "config/app-config";
import { LoadingScreen } from "components/Loading";
import { useRequiredParams } from "hooks";
import { ChantierTabs } from "./components/ChantierTabs";
import { grey } from "@mui/material/colors";
import { getDossiers } from "utils/dossierUtil";
import { TypeDossier } from "models";
import type { Chantier } from "models";
import ComplementsDAdresse from "./components/ComplementsDAdresse";
import AdressePrincipale from "./components/AdressePrincipale";
import DemandeurLine from "./components/DemandeurLine";
import { ChantierProvider } from "providers/ChantierProvider";

const pageTitle = "Chantier";

function ChantierDetails(): ReactNode {
  const { idChantier, idDossier, typeDossier } = useRequiredParams<{
    idChantier: string;
    idDossier: string;
    typeDossier: string | undefined;
  }>();
  const [selectedDossierType, setSelectedDossierType] = useState<TypeDossier>();
  const navigate = useNavigate();
  const [chantier, setChantier] = useState<Chantier>();
  const { catchErrors, isLoading } = useErrorHandler({
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR,
      });
    },
  });

  const updateChantier = useCallback(async (): Promise<void> => {
    async function loadChantier(): Promise<void> {
      const _chantier = await chantierService.getChantier(idChantier);

      setChantier(_chantier);
    }

    await catchErrors(loadChantier);
  }, [catchErrors, idChantier]);

  useEffect(() => {
    void updateChantier();
  }, [updateChantier]);

  const isComplementAdresse = useMemo(
    () =>
      (chantier?.adresse?.lotissement != null && chantier?.adresse?.lotissement !== "") ||
      (chantier?.adresse?.routeDepartementale != null &&
        chantier?.adresse?.routeDepartementale !== "") ||
      (chantier?.adresse?.lot != null && chantier?.adresse?.lot !== "") ||
      (chantier?.adresse?.parcelle != null && chantier?.adresse?.parcelle !== "") ||
      (chantier?.adresse?.section != null && chantier?.adresse?.section !== ""),
    [chantier]
  );

  // Failsafe : si on n'est pas sur une page de dossier, ou de création d'un nouveau dossier de type valide,
  // on navigue vers le premier dossier de la liste.
  useEffect(() => {
    if (chantier != null) {
      if (
        (idDossier == null && typeDossier == null) ||
        (typeDossier != null && !Object.values(TypeDossier).includes(typeDossier as TypeDossier))
      ) {
        navigate(
          routesConfig.chantierDossier.getParameterPath(
            idChantier,
            `${getDossiers(chantier)?.at(0)?.id}`
          ),
          { replace: true }
        );
      } else {
        setSelectedDossierType(
          getDossiers(chantier).find((dossier) => dossier.id === idDossier)?.type ??
            (typeDossier as TypeDossier)
        );
      }
    }
  }, [chantier, idDossier, idChantier, navigate, typeDossier]);

  if (chantier == null || isLoading) {
    return (
      <PageLayout pageTitle={pageTitle}>
        <LoadingScreen />
      </PageLayout>
    );
  }

  return (
    <PageLayout pageTitle={`${pageTitle} ${chantier.reference}`}>
      <Grid container>
        <Grid container sx={{ pb: 2 }} spacing={1} size={12}>
          <Grid size={12}>
            <Typography variant="subtitle2">
              Demandeur{chantier?.demandeur2 != null && "s"}
            </Typography>
            <DemandeurLine demandeur={chantier.demandeur1} />
            <DemandeurLine demandeur={chantier.demandeur2} />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle2">Adresse du chantier</Typography>
            <AdressePrincipale adresse={chantier.adresse} />
            <Link
              onClick={() => {
                navigate(routesConfig.modifierAdresse.getParameterPath(chantier.adresse.id));
              }}
              component={Button}
              size="small">
              Modifier l'adresse
            </Link>
          </Grid>
          {isComplementAdresse && (
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="subtitle2">Compléments d'adresse</Typography>
              <ComplementsDAdresse adresse={chantier.adresse} />
            </Grid>
          )}
        </Grid>
        <ChantierProvider chantier={chantier} updateChantier={updateChantier} isLoading={isLoading}>
          <Grid size={12}>
            <ChantierTabs />
          </Grid>
          <Grid
            container
            alignContent="flex-start"
            sx={{
              border: "2px solid",
              borderColor: `${selectedDossierType?.toLowerCase() ?? "primary"}.main`,
              minHeight: "50vh",
              background: grey[200],
            }}
            size={12}>
            <Outlet />
          </Grid>
        </ChantierProvider>
      </Grid>
    </PageLayout>
  );
}

export const ChantierDetailsWithErrorBoundary = withPageErrorBoundary(ChantierDetails);
