import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  useErrorHandler,
  useSnackbarErrorHandler,
  withPageErrorBoundary,
} from "utils/errorHandling";
import { useDossierPerimetre, useRequiredParams } from "hooks";
import {
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingButton from "components/LoadingButton";
import { TypeDossier } from "models";
import type { Avis, PaginatedResult } from "models";
import { avisService, dossierService } from "services";
import { useChantier } from "providers";
import { grey } from "@mui/material/colors";
import { FormSection } from "components/Layout";
import DossierChip from "pages/Recherche/components/DossierChip";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "config/app-config";
import { dateUtil } from "@sdeapps/react-core";
import { enqueueSnackbar } from "notistack";
import { getDossiers } from "utils/dossierUtil";

const dateFormat = "yyyy-MM-dd";

function NewDossierPage(): ReactNode {
  const { idChantier, typeDossier } = useRequiredParams<{
    idChantier: string;
    // On sait des conditions de ChantierDetails que typeDossier est TypeDossier valide
    typeDossier: TypeDossier;
  }>();
  const [selectedAvis, setSelectedAvis] = useState<Avis>();
  const [results, setResults] = useState<PaginatedResult<Avis>>();

  const { chantier, updateChantier } = useChantier();
  const navigate = useNavigate();

  const { catchErrors, isLoading } = useSnackbarErrorHandler({
    defaultIsLoading: false,
    SdeappsErrorBadRequest: (error) => {
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
  });
  const { catchErrors: catchAvisErrors, isLoading: isAvisLoading } = useErrorHandler({
    defaultIsLoading: false,
  });
  const { getPerimetresByCommune } = useDossierPerimetre();

  useEffect(() => {
    setSelectedAvis(undefined);

    // failsafe : si il y a déjà un dossier existant de ce type dans le chantier, on y redirige l'utilisateur
    if (chantier != null) {
      const alreadyExistsDossier = getDossiers(chantier).find((d) => d.type === typeDossier);
      if (alreadyExistsDossier != null) {
        navigate(
          routesConfig.chantierDossier.getParameterPath(chantier.id, alreadyExistsDossier.id)
        );
      }
    }
  }, [typeDossier, chantier, navigate]);

  function createDossier(): void {
    void catchErrors(async () => {
      if ((typeDossier === TypeDossier.PAC || selectedAvis != null) && typeDossier != null) {
        // Code INSEE du chantier: priorité à la valeur de l'avis, fallback sur la valeur de l'adresse du chantier
        const codeInseeCommune: string | undefined =
          selectedAvis?.codeInseeCommune ?? chantier?.adresse?.codeInsee;
        const { perimetreEau, perimetreAss } = await getPerimetresByCommune(codeInseeCommune);

        const newDossierId = await dossierService.createDossier(idChantier, {
          idAvis: selectedAvis?.idAvis,
          type: typeDossier,
          perimetre: typeDossier === TypeDossier.EAU ? perimetreEau : perimetreAss,
        });
        await updateChantier();

        navigate(routesConfig.chantierDossier.getParameterPath(idChantier, newDossierId));
      }
    });
  }

  useEffect(() => {
    async function getAvisRecommandations(): Promise<void> {
      setResults(undefined);
      const _results = await avisService.getAllAvis({
        codeInseeCommune: chantier?.adresse.codeInsee,
        demandeur: chantier?.demandeur1.codeSap,
        type: typeDossier,
        onlyWithDossier: false,
        offset: 0,
        size: 1000,
      });
      // on range les plus récents en premier
      _results.result.sort((r1, r2) =>
        dateUtil.compareDesc(
          dateUtil.fromFormatToDate(r1.dateDemande, dateFormat),
          dateUtil.fromFormatToDate(r2.dateDemande, dateFormat)
        )
      );
      setResults(_results);
    }

    if (typeDossier != null) {
      void catchAvisErrors(getAvisRecommandations);
    }
  }, [catchAvisErrors, chantier, typeDossier]);

  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSelectedAvis(
      results?.result.find((a) => a.idAvis === (event.target as HTMLInputElement).value)
    );
  }

  if (typeDossier === TypeDossier.PAC) {
    return (
      <Grid container size={12}>
        <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
          <FormSection>
            <Grid size={{ xs: 12 }}>
              <LoadingButton onClick={createDossier} loading={isLoading}>
                Ajouter un Dossier {typeDossier}
              </LoadingButton>
            </Grid>
          </FormSection>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container size={12}>
      <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
        <FormSection title={`Sélectionner un avis ${typeDossier}`}>
          <Grid size={{ xs: 12 }}>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={selectedAvis?.idAvis ?? ""}
              onChange={handleRadioChange}>
              <TableContainer>
                <Table>
                  <TableHead sx={{ ".MuiTableCell-head .MuiTypography-root": { fontWeight: 600 } }}>
                    <TableRow>
                      <TableCell size="small" padding="checkbox"></TableCell>
                      <TableCell>
                        <Typography>Id</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Commune</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Demandeur(s)</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isAvisLoading && (
                      <TableRow>
                        <TableCell size="small" padding="checkbox">
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    )}
                    {results?.result?.map((avis) => (
                      <TableRow key={avis.idAvis}>
                        <TableCell size="small" padding="checkbox">
                          <Radio value={avis.idAvis} />
                        </TableCell>
                        <TableCell>
                          <Typography>{avis.idAvis}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {dateUtil.format(
                              dateUtil.fromFormatToDate(avis.dateDemande, dateFormat),
                              "dd/MM/yyyy"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            <DossierChip sx={{ marginY: -1 }} type={avis.typeDemande} />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{avis.nomCommune}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {avis.demandeur1.nom} {avis.demandeur1.prenom}
                            {avis.demandeur2 != null && (
                              <>
                                / {avis.demandeur2.nom} {avis.demandeur2.prenom}
                              </>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </RadioGroup>
          </Grid>
          <Grid>
            <LoadingButton
              onClick={createDossier}
              loading={isLoading}
              disabled={selectedAvis == null || typeDossier == null}>
              Ajouter un Dossier {typeDossier}
            </LoadingButton>
          </Grid>
        </FormSection>
      </Grid>
    </Grid>
  );
}

export const NewDossierWithErrorBoundary = withPageErrorBoundary(NewDossierPage);
